@import "node_modules/react-modal-video/scss/modal-video.scss";
@import "slick-carousel/slick/slick.css";
@import "~bootstrap/scss/bootstrap";
@import "swiper/css/bundle";
@import "/public/assets/scss/main.scss";

h4{
    margin-top: 40px;
    margin-bottom: 40px;
}

.thank-you-title {
    color: #7B42F7;
    font-size: 85px;
    font-weight: bold;
    letter-spacing: -3px;
    line-height: 1;
}
