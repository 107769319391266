@use '../utils' as *;
/*-----------------------------------------------------------------------------------

    Theme Name: Softec - Data analytics HTML5 Template
    Author: Theme Pure
    Support: https://help.themepure.net/support/
    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

    01. THEME DEFAULT CSS
	02. MEANMENU CSS START
	03. ABOUT CSS START
	04. ACCOUNT CSS START
	05. BLOG CSS START
    06. BRAND CSS START
    07. CAREER CSS START
    08. CONTACT CSS START
	09. COUNTER CSS START
	10. CTA CSS START
	11. ERROR CSS START
	12. FAQ CSS START
	13. FEATURE CSS START
	14. FOOTER CSS START
	15. HEADER CSS START
	16. HERO CSS START
	17. INTEGRATION CSS START
	18. PAYMENT CSS START
	19. PRICE CSS START
	20. PROJECT CSS START
	21. RANK CSS START
	22. SECURITY CSS START
	23. SERVICE CSS START
	24. TEAM CSS START
	25. TESTIMONIAL CSS START
    26. VIDEO CSS START

**********************************************/

/*----------------------------------------*/
/*  01. THEME DEFAULT CSS START
/*----------------------------------------*/

@import url($font-url);
// @media (min-width: 1200px){
//     .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
//         max-width: 1200px;
//     }
// }
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
	--bs-gutter-x: 30px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*---------------------------------
	typography css start 
---------------------------------*/
body {
    font-family: var(--tp-ff-body);
    font-size: 15px;
    font-weight: 400;
    color: var(--tp-text-body);
    line-height: 1.3;
    overflow-x: hidden;
}
body.monserat {
    font-family: var(--tp-ff-monserat);
}

html,body{
    overflow-x: hidden;
}

a {
    text-decoration: none;
    transition: .3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--tp-ff-heading);
    color: var(--tp-heading-primary);
    margin-top: 0px;
    font-weight: 700;
    line-height: 1.1;
    @include transition(.3s);
}

img {
    max-width: 100%;
    height: auto;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 16px;
}

ul {
    margin: 0px;
    padding: 0px;
}

p {
    color: var(--tp-text-body);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.z-index {
    position: relative;
    z-index: 2;
}
.z-index-3 {
    position: relative;
    z-index: 3;
}
.z-index-4 {
    position: relative;
    z-index: 4;
}
.z-index-5 {
    position: relative;
    z-index: 5;
}
.z-index-6 {
    position: relative;
    z-index: 6;
}

a,
.btn,
button,
input,
select,
textarea,
li,
img,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
    @include transition(.3s);
}

a:focus,
.button:focus {
    text-decoration: none;
    outline: none;
}

a:focus,
a:hover {
    color: inherit;
    text-decoration: none;
}

a,
button {
    color: inherit;
    outline: none;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

button:focus {
    outline: 0;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

input,
textarea {
    outline: none;
    color: var(--tp-common-black);
    @include tp-placeholder{
        color: #70737D;       
    }
}

input[type="color"] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 50%;
}

*::-moz-selection {
    background: var(--tp-theme-1);
    color: var(--tp-common-white);
    text-shadow: none;
}

::-moz-selection {
    background: var(--tp-theme-1);
    color: var(--tp-common-white);
    text-shadow: none;
}

::selection {
    background: var(--tp-theme-1);
    color: var(--tp-common-white);
    text-shadow: none;
}


*::-moz-placeholder {
    color: var(--tp-common-black);
    font-size: var(--tp-fz-body);
    opacity: 1;
}

*::placeholder {
    color: var(--tp-common-black);
    font-size: var(--tp-fz-body);
    opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/

.w-img {
    & img {
        width: 100%;
        height: auto;
    }
}

.m-img {
    & img {
        max-width: 100%;
    }
}

.fix {
    overflow: hidden
}

.clear {
    clear: both;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.overflow-y-visible {
    overflow-x: hidden;
    overflow-y: visible;
}

.p-relative {
    position: relative;
}

.p-absolute {
    position: absolute;
}

.include-bg {
    @include background();
}

.gx-10 {
    --bs-gutter-x: 10px;
}
.gx-30 {
    --bs-gutter-x: 30px;
}
.gx-40 {
    --bs-gutter-x: 40px;
}

.gx-50 {
    --bs-gutter-x: 12px;
}
.gx-60 {
    --bs-gutter-x: 65px;
}
.gx-20 {
    --bs-gutter-x: 20px;
}
.gx-25 {
    --bs-gutter-x: 25px;
}
.gx-60 {
    --bs-gutter-x: 60px;
    @media #{$lg}{
        --bs-gutter-x: 40px; 
    }
}

