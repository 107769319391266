@use '../utils' as *;

/*---------------------------------------*/
/*Background color
/*-----------------------------------------*/

.grey-bg {
	background: var(--tp-grey-7);
}
.grey-bg-2 {
	background: var(--tp-grey-6);
}
.grey-bg-3 {
	background: var(--tp-grey-8);
}
.white-bg {
	background: var(--tp-common-white);
}
.black-bg {
	background: var(--tp-common-black);
}
.theme-bg {
	background: var(--tp-theme-1);
}
.theme-bg-3 {
	background: var(--tp-theme-3);
}
.blue-bg{
	background:var(--tp-common-blue-2);
}
.blue-bg-2{
	background:var(--tp-common-blue-4);
}

.purple-bg{
	background:#7A41F8;
}

.black-bg{
	background:#000;
}

.purple-bg-2{
	background:#cb80ff;
}
